.circle-animation {
  position: absolute;
  width: 125px;
  height: 125px;
  background: rgba(255, 255, 255, 0.5) !important;
  border-radius: 50%;
  animation: animateCircle 1.5s linear infinite;
}

@keyframes animateCircle {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
