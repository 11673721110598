.rdw-editor-toolbar {
  min-height: 40px;
  border-radius: 6px;
  margin-bottom: 12px;
  border: 1px solid #d2d6da;
}

.rdw-dropdown-wrapper {
  position: relative;
}

.rdw-dropdown-optionwrapper {
  padding: 6px;
  position: absolute;
  left: 0;
  top: 31px;
  min-width: 135px;
  overflow-y: auto;
}

.rdw-dropdownoption-default {
  margin-bottom: 3px;
}

.rdw-option-disabled {
  background-color: #f0f2f5;
}

.rdw-dropdownoption-active {
  border-radius: 4px;
}

.rdw-dropdownoption-highlighted {
  border-radius: 4px;
}

.rdw-link-modal-input {
  border: 1px solid #d2d6da;
  height: 40px;
  margin-bottom: 8px;
}

.rdw-link-modal-target-option {
  display: flex;
}

.rdw-link-modal-btn,
.rdw-link-modal-input,
.rdw-dropdown-optionwrapper,
.rdw-dropdown-wrapper,
.rdw-option-wrapper,
.rdw-link-modal {
  border-radius: 6px;
}

.rdw-link-modal {
  box-shadow: 1px 2px 4px #bfbdbd;
}

.rdw-link-modal-btn:disabled {
  background: #f0f2f5;
  cursor: default;
  box-shadow: none;
}
